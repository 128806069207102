<nav class="navbar navbar-expand-lg">
    <div class="container">
        <div class="row justify-content-center w-100">
            <div class="col-12">
                <a class="navbar-brand" href="/">
                    <img class="logo-cadastro" src="Logo_HR.png" alt="">
                </a>
            </div>
        </div>
    </div>
</nav>
<div class="container" style="padding-top: 10px;" *ngIf="cadastro">
    <section class="cool-facts-area bg-img bg-branco">
        <div class="container">
            <div class="single-widget-area">
                <div class="single-widget-area">
                    <div class="row author-widget mat-elevation-z8">
                        <div class="car-title mb-4 text-center">
                            <h3 class="title">Cadastrar Parceiro</h3>
                        </div>
                        <form #f="ngForm" (ngSubmit)="salvar(f.form)">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="full-height">
                                        <div class="card card-round">
                                            <a href="#" uploadButton [uploadOptions]="options" [uploadComplete]="onComplete">
                                                <img [src]="uploadedFileUrl || 'img-upload.png'" alt="Logo da empresa">
                                            </a>
                                        </div>
                                    </div>
                                    <p style="font-size: 12px;text-align: center;"> A imagem será apresentada no formato acima na pagina personalizada da empresa</p>
                                </div>
                                <div class="col-md-8" style="margin: auto;">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field appearance="outline" style="width: 100%">
                                                <mat-label>Nome da empresa</mat-label>
                                                <input type="text" matInput [(ngModel)]="parceiro.nomeEmpresa"
                                                    name="nomeEmpresa" maxlength="100" required
                                                    >
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field appearance="outline" style="width: 100%">
                                                <mat-label>Nome do contato</mat-label>
                                                <input type="text" matInput [(ngModel)]="parceiro.nomeContato"
                                                    name="nomeContato" maxlength="100" required
                                                    >
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field appearance="outline" style="width: 100%">
                                                <mat-label>Telefone do contato</mat-label>
                                                <input type="text" matInput mask="(00) 0 0000-0000||(00) 0000-0000"
                                                    [(ngModel)]="parceiro.foneContato" name="foneContato" required
                                                    >
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field appearance="outline" style="width: 100%">
                                                <mat-label>e-mail</mat-label>
                                                <input type="text" matInput
                                                    [(ngModel)]="parceiro.email" name="email" required email 
                                                    >
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field appearance="outline" style="width: 100%">
                                                <mat-label>Rede social / site</mat-label>
                                                <input type="text" matInput [(ngModel)]="parceiro.site" name="site"
                                                    maxlength="100" required >
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field appearance="outline" style="width: 100%">
                                                <mat-label>N° da carteria</mat-label>
                                                <input type="text" matInput [(ngModel)]="parceiro.numCarteira" name="numCarteira"
                                                    maxlength="100" >
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-md-12">
                                    <mat-form-field appearance="outline" style="width: 100%">
                                        <mat-label>Observações</mat-label>
                                        <textarea matInput [(ngModel)]="parceiro.observacao" style="width: 100%"
                                            maxlength="600" name="observacao" ></textarea>
                                    </mat-form-field>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button *ngIf="!isLoading" type="submit" class="btn alazea-btn" mat-button>CADASTRAR</button>
                                <div *ngIf="isLoading" class="loading-spinner">
                                    <i class="fas fa-spinner fa-spin"></i>
                                </div>
                                <a *ngIf="!isLoading" href="/" class="btn alazea-btn-grey" mat-button>
                                    VOLTAR
                                </a>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </section>
</div>

<section id="assine" class="section-padding-home" *ngIf="!cadastro">
    <div class="container">
        <div class="section-heading">
            <div class="row">
                <div class="col-sm-6 margin-auto ">
                    <div class="single-benefits-area bg-branco">
                        <div class="subscription-card h-100">
                            <h2 class="plan-title">Seu cadastro foi concluído com sucesso.</h2>
                            <ul class="features" *ngIf="!uploadedFileUrl || !parceiro.numCarteira">
                                <li style="margin-top: 20px;" >Há algumas pendências que precisam ser ajustadas posteriormente para que sua área personalizada fique diponível:</li>
                                <li *ngIf="!parceiro.numCarteira"><i class="fa-solid fa-circle-exclamation"></i>Informar o numero da carteria</li>
                                <li *ngIf="!uploadedFileUrl"><i class="fa-solid fa-circle-exclamation"></i>Cadastrar a logo marca</li> 
                                <li >Por favor, clique no botão abaixo para abrir a tela de pagamento.</li>
                            </ul>
                            <ul class="features" *ngIf="uploadedFileUrl && parceiro.numCarteira">
                                <li style="text-align: center;">Por favor, clique no botão abaixo para abrir a tela de pagamento.</li>
                            </ul>
                            <div class="modal-footer" style="margin-top: 20px;">
                                <a  href="https://cielolink.com.br/3VxmKcq" class="btn alazea-btn w-100" mat-button>INICIAR PAGAMENTO</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</section>

<!-- <div class="container" style="padding-top: 10px; max-width:600px;" *ngIf="!cadastro">
    <section class="cool-facts-area bg-img bg-branco">
        <div class="container">
            <div class="single-widget-area">
                <div class="single-widget-area">
                    <div class="row author-widget mat-elevation-z8">
                        <div class="car-title mb-4 text-center">
                            <h3 class="title">Seu cadastro foi concluído com sucesso.</h3>
                        </div>
                        <div class="row d-flex  justify-content-center" *ngIf="!cadastro">
                            <div class="col-md-12">
                                <p style="margin-top: 20px;" *ngIf="!uploadedFileUrl || !parceiro.numCarteira">No entanto, há algumas pendências que precisam ser ajustadas posteriormente para que sua área personalizada fique diponível:</p>
                                <ul style="margin-top: 20px;" *ngIf="!uploadedFileUrl || !parceiro.numCarteira">
                                    <li *ngIf="!parceiro.numCarteira"><i class="fa-solid fa-circle-exclamation"></i>Informar o numero da carteria</li>
                                    <li *ngIf="!uploadedFileUrl"><i class="fa-solid fa-circle-exclamation"></i>Cadastrar a logo marca</li>
                                </ul>
                                <p style="margin-top: 20px;">Por favor, clique no botão abaixo para abrir a tela de pagamento.</p>
                            </div>
                            <div class="col-md-12 modal-footer" style="margin-top: 20px;">
                                <a href="https://cielolink.com.br/3VxmKcq" class="btn alazea-btn" mat-button>FAZER ASSINATURA</a>
                                <a href="/" class="btn alazea-btn-grey" mat-button>VOLTAR</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</div> -->