<section class="hero-area">
    <div class="single-hero-post bg-overlay">
        <div class="slide-img bg-img bg-img-principal" style="background-image: url(home/bg-home.jpg);">
        </div>
        <div class="container h-100 ">
            <div class="row h-100 align-items-center">
                <div class="col-md-6 hero-slides-content mb-20">
                    <img src="Logo_HR.png" alt="">
                    <h1 style="text-transform: none; font-size: 35px; text-align: initial !important;">Compromisso
                        ambiental empresarial por menos de R$100/mês</h1>
                    <button (click)="scrollToSection('produto')" class="btn alazea-btn" mat-raised-button
                        color="primary" style="margin-right: 5px">
                        Entenda a proposta
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>


<section id="produto" class="section-padding-home">
    <div class="container">
        <div class="section-heading">
            <div class="row">
                <div class="col-sm-6">
                    <div class="single-benefits-area">
                        <img src="/home/oquefazemos.png" alt="">
                    </div>
                </div>
                <div class="col-sm-6 margin-auto">
                    <div class="single-benefits-area">
                        <h2>O que fazemos</h2>
                        <p>Promovemos a preservação de fauna, flora e rios. Fazemos auditoria de áreas ambientais
                            privadas e emitimos um token chamado RAI (reserva ambiental inteligente). A cada ano
                            realizamos novas auditorias para averiguar a preservação das áreas. Estas são algumas fotos
                            de câmeras instaladas nessas áreas monitoradas. <b>Lançamos neste mês de agosto de 2024
                                nossa assinatura do Reservas para empresas!</b></p>
                        <br>
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <div class="card card-round card-round-fauna" style="background-image: url(/home/fauna/fauna-1.jpeg);">
                                </div>
                            </div>
                
                            <div class="col-12 col-md-3">
                                <div class="card card-round card-round-fauna" style="background-image: url(/home/fauna/fauna-2.jpeg);">
                                </div>
                            </div>
                
                            <div class="col-12 col-md-3" >
                                <div class="card card-round card-round-fauna" style="background-image: url(/home/fauna/fauna-3.jpeg);">
                                </div>
                            </div>
                
                            <div class="col-12 col-md-3" >
                                <div class="card card-round card-round-fauna" style="background-image: url(/home/fauna/fauna-4.jpeg);">
                                </div>
                            </div>
                
                        </div>        
                        <br>
                        <button (click)="scrollToSection('assine')" class="btn alazea-btn" mat-raised-button
                            color="primary" style="margin-right: 5px">
                            Assine agora
                        </button>
                    </div>
                </div>

            </div>
        </div>


    </div>
</section>

<section id="parceiros" class="subscribe-newsletter-area bg-verde">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-heading text-center">
                    <h2 style="color: #fff !important;">Parceiros</h2>
                    <p style="color: #fff !important;">Temos diversos parceiros que aceitam tokens RAI como pagamento
                        pelos seus produtos e serviços
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container" style="padding: 20px;">
        <div class="row">
            <div class="col-12 col-md-3">
                <!-- <a href="/parceiro"> -->
                    <div class="card card-round">
                        <img src="/home/parceiros/logo-blue.png" alt="Imagem 1" style="padding: 20px;">
                    </div>
                <!-- </a> -->
            </div>

            <div class="col-12 col-md-3">
                <!-- <a href="/parceiro"> -->
                <div class="card card-round">
                    <img src="/home/parceiros/logo-gran7.png" alt="Imagem 2">
                </div>
                <!-- </a> -->
            </div>

            <div class="col-12 col-md-3">
                <!-- <a href="/parceiro"> -->
                <div class="card card-round">
                    <img src="/home/parceiros/logo-msagro.jpg" alt="Imagem 3">
                </div>
                <!-- </a> -->
            </div>

            <div class="col-12 col-md-3">
                <!-- <a href="/parceiro"> -->
                <div class="card card-round">
                    <img src="/home/parceiros/logo-rotafrutas.png" alt="Imagem 3">
                </div>
                <!-- </a> -->
            </div>

            <div class="col-12 col-md-3">
                <!-- <a href="/parceiro"> -->
                <div class="card card-round">
                    <img src="/home/parceiros/logo-safra.jpg" alt="Imagem 3">
                </div>
                <!-- </a> -->
            </div>

            <div class="col-12 col-md-3">
                <!-- <a href="/parceiro"> -->
                <div class="card card-round">
                    <img src="/home/parceiros/logo-smtratores.png" alt="Imagem 3">
                </div>
                <!-- </a> -->
            </div>

        </div>
    </div>

</section>


<section id="assine" class="section-padding-home">
    <div class="container">
        <div class="section-heading">

            <div class="row">
                
                <div class="col-sm-6 margin-auto">
                    <div class="single-benefits-area">
                        <div class="subscription-card h-100">
                            <h2 class="plan-title">ASSINATURA RESERVAS</h2>
                            <p class="price">R$ 99,90<span class="text-muted">/mês</span></p>

                            <ul class="features">
                                <li>Preço por cada unidade comercial. Está incluso na assinatura:</li>
                                <li><i class="fa-regular fa-circle-check icon"></i>1 Mídia kit para divulgação </li>
                                <li><i class="fa-regular fa-circle-check icon"></i>Site personalizado dentro do Reservas
                                </li>
                                <li><i class="fa-regular fa-circle-check icon"></i>10 tokens RAI por mês equivalente a
                                    R$100</li>
                                <li><i class="fa-regular fa-circle-check icon"></i>Divulgação do parceiro</li>
                            </ul>
                            <a  [href]="'/cadastro'" class="btn alazea-btn" mat-raised-button color="primary" style="margin-right: 5px">
                                Assine agora
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="single-benefits-area">
                        <img src="/home/estabelecimento.png" alt="">
                    </div>
                </div>
            </div>
        </div>


    </div>
</section>

<!-- ##### Footer Area Start ##### -->
<footer id="contato" class="footer-area">
    <!-- Main Footer Area -->
    <div class="main-footer-area">
        <div class="container">
            <div class="row">

                <!-- Single Footer Widget -->
                <div class="col-12 col-sm-6 col-lg-3">
                    <div class="footer-logo mb-30">
                        <img src="Logo_HR.png" alt="">
                    </div>
                </div>

                <!-- Single Footer Widget -->
                <div class="col-12 col-sm-6 col-lg-3">
                    <div class="single-footer-widget">
                        <div class="widget-title">
                            <h5>Horário de Atendimento</h5>
                        </div>

                        <div class="contact-information">
                            <p>De segunda a sexta das 8 às 18 horas</p>
                        </div>
                    </div>
                </div>

                <!-- Single Footer Widget -->
                <div class="col-12 col-sm-6 col-lg-3">
                    <div class="single-footer-widget">
                        <div class="widget-title">
                            <h5>Localização</h5>
                        </div>

                        <div class="contact-information">
                            <P>Edifício Business Point, sala 1015 Brasília-DF CEP 70.070-934</P>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-6 col-lg-3">
                    <div class="single-footer-widget">
                        <div class="widget-title">
                            <h5>Rede Social</h5>
                        </div>

                        <div class="contact-information">
                            <div class="social-info">
                                <a href="https://www.instagram.com/reservasinteligentes/" target="_blank">
                                    <i class="fa-brands fa-instagram"></i>
                                </a>
                                <a href="https://api.whatsapp.com/send?phone=5511972731527&text=Ol%C3%A1,%20gostaria%20de%20tirar%20algumas%20d%C3%BAvidas%20sobre%20a%20assinatura%20do%20Reservas%20para%20empresas."
                                    target="_blank">
                                    <i class="fa-brands fa-whatsapp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="single-footer-widget">
                        <div class="contact-information">
                            <p style="font-size: 10px;">2024 © RESERVAS INTELIGENTES - TODOS OS DIREITOS RESERVADOS -
                                CNPJ: 42.273.515/0001-05</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="help-tuor-chat">
    <a href="https://api.whatsapp.com/send?phone=5511972731527&text=Ol%C3%A1,%20gostaria%20de%20tirar%20algumas%20d%C3%BAvidas%20sobre%20a%20assinatura%20do%20Reservas%20para%20empresas."
        target="_blank" style="color: #fff;">
        <i class="fa-brands fa-whatsapp fa-shake icon-help-tuor"></i>
    </a>
</div>
<!-- ##### Footer Area End ##### -->