import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParceiroService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  salvar(fileToUpload: File, parceiro: any) {
    const formData: FormData = new FormData();
    if (fileToUpload) {
      formData.append('file', fileToUpload, fileToUpload.name);
    }
    formData.append('parceiro', JSON.stringify(parceiro));
    return this.http.post<any>(`${this.getUrlBase()}admin/parceiro/metadado`, formData)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    console.log('error service: ', error);
    let errorMessage = 'Ocorreu um erro desconhecido!';
    if (error.error) {
      errorMessage = `${error.error.message}`;
    } else {
      errorMessage = `Código do erro: ${error.status}\nMensagem: ${error.message}`;
    }
    return throwError(() => new Error(errorMessage));
  }

}
