import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';



@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if( request.body ){
      request = request.clone({
        body: this.encodeBody(request.body, environment.key)
      });
    }

    return next.handle(request);
  }

  encodeBody(body:any, key:any) {
    //console.log("RequestInterceptor",body)
    if( !(body instanceof FormData) ){
      var cry = CryptoJS.AES.encrypt(JSON.stringify(body), key).toString();;
      return {token:cry};
    }else{
      return body;
    }
  }


}
