import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: `
        <div style="padding: 20px;">
            <h2 mat-dialog-title>Ocorreu um erro ao executar a operação</h2>
            <mat-dialog-content>
            {{ data.message }}
            </mat-dialog-content>
        </div>
  `
})
export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string }) {}
}