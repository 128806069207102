import * as i0 from '@angular/core';
import { Component, Input, ViewChild, Directive, HostListener, NgModule } from '@angular/core';
import { UploadWidget } from '@bytescale/upload-widget';
const _c0 = ["container"];
class UploadDropzoneComponent {
  options;
  onComplete;
  onUpdate;
  width = "600px";
  height = "375px";
  container;
  ngAfterViewInit() {
    const onUpdateParams = this.onUpdate === undefined ? {} : {
      onUpdate: this.onUpdate
    };
    UploadWidget.open({
      ...this.getOptions(),
      ...onUpdateParams,
      container: this.container.nativeElement,
      layout: "inline"
    }).then(files => {
      if (this.onComplete !== undefined) {
        this.onComplete(files);
      }
    }, error => console.error("Uploader error.", error));
  }
  getOptions() {
    if (this.options === undefined) {
      throw new Error("[bytescale-upload-widget] You must provide the 'options' attribute to the 'UploadDropzone' component.");
    }
    return this.options;
  }
  static ɵfac = function UploadDropzoneComponent_Factory(t) {
    return new (t || UploadDropzoneComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: UploadDropzoneComponent,
    selectors: [["upload-dropzone"]],
    viewQuery: function UploadDropzoneComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
      }
    },
    inputs: {
      options: "options",
      onComplete: "onComplete",
      onUpdate: "onUpdate",
      width: "width",
      height: "height"
    },
    decls: 2,
    vars: 4,
    consts: [["container", ""]],
    template: function UploadDropzoneComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelement(0, "div", null, 0);
      }
      if (rf & 2) {
        i0.ɵɵstyleMapInterpolate2("position: relative; width: 100%; min-width: 280px; maxWidth: ", ctx.width, "; height: ", ctx.height, "");
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploadDropzoneComponent, [{
    type: Component,
    args: [{
      selector: "upload-dropzone",
      template: `
    <div
      #container
      style="position: relative; width: 100%; min-width: 280px; maxWidth: {{ width }}; height: {{ height }}"
    ></div>
  `
    }]
  }], null, {
    options: [{
      type: Input,
      args: ["options"]
    }],
    onComplete: [{
      type: Input,
      args: ["onComplete"]
    }],
    onUpdate: [{
      type: Input,
      args: ["onUpdate"]
    }],
    width: [{
      type: Input,
      args: ["width"]
    }],
    height: [{
      type: Input,
      args: ["height"]
    }],
    container: [{
      type: ViewChild,
      args: ["container"]
    }]
  });
})();
class UploadButtonDirective {
  options;
  onComplete;
  onClick(event) {
    event.preventDefault();
    UploadWidget.open(this.getOptions()).then(files => {
      if (this.onComplete !== undefined) {
        this.onComplete(files);
      }
    }, error => console.error("Uploader error.", error));
  }
  ngOnInit() {
    try {
      this.getOptions();
    } catch (e) {
      console.error(e);
    }
  }
  getOptions() {
    if (this.options === undefined) {
      throw new Error("[bytescale-upload-widget] You must provide the 'uploadOptions' attribute to elements marked with the 'uploadButton' directive.");
    }
    return this.options;
  }
  static ɵfac = function UploadButtonDirective_Factory(t) {
    return new (t || UploadButtonDirective)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: UploadButtonDirective,
    selectors: [["", "uploadButton", ""]],
    hostBindings: function UploadButtonDirective_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("click", function UploadButtonDirective_click_HostBindingHandler($event) {
          return ctx.onClick($event);
        });
      }
    },
    inputs: {
      options: [0, "uploadOptions", "options"],
      onComplete: [0, "uploadComplete", "onComplete"]
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploadButtonDirective, [{
    type: Directive,
    args: [{
      selector: "[uploadButton]"
    }]
  }], null, {
    options: [{
      type: Input,
      args: ["uploadOptions"]
    }],
    onComplete: [{
      type: Input,
      args: ["uploadComplete"]
    }],
    onClick: [{
      type: HostListener,
      args: ["click", ["$event"]]
    }]
  });
})();
class UploadWidgetModule {
  static ɵfac = function UploadWidgetModule_Factory(t) {
    return new (t || UploadWidgetModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: UploadWidgetModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploadWidgetModule, [{
    type: NgModule,
    args: [{
      declarations: [UploadDropzoneComponent, UploadButtonDirective],
      imports: [],
      exports: [UploadDropzoneComponent, UploadButtonDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of @bytescale/upload-widget-angular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { UploadButtonDirective, UploadDropzoneComponent, UploadWidgetModule };
