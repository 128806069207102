import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ParceiraComponent } from './parceira/parceira.component';
import { CadastroComponent } from './cadastro/cadastro.component';

export const routes: Routes = [
  { path: 'parceiro', component: ParceiraComponent },
  { path: 'cadastro', component: CadastroComponent },
  { path: '', component: HomeComponent },
  { path: '', redirectTo: '', pathMatch: 'full' }
];
