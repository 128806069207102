import { Component } from '@angular/core';

@Component({
  selector: 'app-parceira',
  standalone: true,
  imports: [],
  templateUrl: './parceira.component.html',
  styleUrl: './parceira.component.scss'
})
export class ParceiraComponent {

}
