<header class="hero text-center">
  <div class="container">
    <div class="row">
      <div class="col-md-6" style="margin: auto;">
        <div class="card card-round">
          <img src="/home/parceiros/logo-blue.png" alt="Imagem 1" style="padding: 20px;">
        </div>
      </div>
      <div class="col-md-6">
        <img src="/parceria/mundoparceiro.png" alt="Imagem 1" >
      </div>
    </div>
  </div>
  </header>

<section id="features" class="features-section container">
  <div class="row text-center">
    <div class="col-md-4 mb-4">
      <div class="feature-card h-100">
        <i class="fas fa-laptop-code"></i>
        <h5>Ferramentas Avançadas</h5>
        <p>Tenha acesso a uma plataforma completa com recursos modernos para gerenciar suas reservas.</p>
      </div>
    </div>

    <div class="col-md-4 mb-4">
      <div class="feature-card h-100">
        <i class="fas fa-chart-line"></i>
        <h5>Análises de Desempenho</h5>
        <p>Acompanhe o desempenho do seu negócio com relatórios detalhados e insights em tempo real.</p>
      </div>
    </div>

    <div class="col-md-4 mb-4">
      <div class="feature-card h-100">
        <i class="fas fa-headset"></i>
        <h5>Suporte Dedicado</h5>
        <p>Conte com um time de suporte especializado disponível 24/7 para atender suas necessidades.</p>
      </div>
    </div>
  </div>
</section>

<section class="cta-section">
  <div class="container">
    <h2>Explore nossos Benefícios Exclusivos</h2>
    <a href="https://reservasinteligentes.com.br" target="_blank" class="btn btn-custom">Visitar Site do Parceiro</a>
  </div>
</section>

<!-- Rodapé -->
<footer class="text-center py-4">
  <p>&copy; 2024 Parceiro Inteligente. Todos os direitos reservados.</p>
</footer>