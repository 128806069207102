import { map, tap } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, filter } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(tap(event => {
      if (event.type === HttpEventType.Response) {
        var decBody =  this.decodedBody(event.body, environment.key);
        var evt = event.clone({ body: decBody })
        //console.log("ResponseInterceptor",evt)
        return evt;
      }else{
        return null;
      }
    }));
    
  }

  decodedBody(body:any, key:any) {

    if( body && body.resp ){
      var bytes  = CryptoJS.AES.decrypt(body.resp, key);
      var originalText = bytes.toString(CryptoJS.enc.Utf8);
      if( this.isJSON(originalText) ){
        originalText = JSON.parse(JSON.parse(originalText));
      }
      return originalText;
    }
    return body;
  }

  isJSON(text:any){
    try {
      JSON.parse(text);
    } catch (error) {
      return false;
    }
    return true;
  }


}
