export const environment = {
    production: false,
    apiUrl: '//devcore.reservasinteligentes.com.br/api',
    key:'f4048a46-7b0d-4808-8ccc-b94f7fe204ed',
    //apiUrl: '//scaling-space-adventure-r7qr6rp6xcwq95-3000.app.github.dev/api',
    //key:'4bd67b92-faaa-4f5a-aa13-d4c2025e59fb',
    bsc_params: {
        chainId: '0x61',
        chainName: 'Testnet Binance Smart Chain',
        nativeCurrency: {
          name: 'Binance Coin',
          symbol: 'BNB',
          decimals: 18
        },
        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
        blockExplorerUrls: ['https://testnet.bscscan.com'],
        apiUrl: "https://api-testnet.bscscan.com"
      },
    contractAddress:'0xa67e96e4ee5678a05a21e32278abd740593cdd9f'
};
