import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UploadWidgetConfig, UploadWidgetResult } from '@bytescale/upload-widget';
import { UploadWidgetModule } from "@bytescale/upload-widget-angular";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ParceiroService } from '../services/parceiro.service';
import { ErrorDialogComponent } from './errodialog.component';

@Component({
  selector: 'app-cadastro',
  standalone: true,
  imports: [
    MatCardModule, 
    FormsModule, 
    CommonModule, 
    MatFormFieldModule, 
    MatInputModule, 
    UploadWidgetModule, 
    NgxMaskDirective,
    NgxMaskPipe, 
    MatDialogModule],
  templateUrl: './cadastro.component.html',
  styleUrl: './cadastro.component.scss',
  providers: [provideNgxMask()]
})
export class CadastroComponent implements OnInit{

  parceiro: any = {};
  arqLogo: any | undefined = undefined;
  uploadedFileUrl: string | undefined = undefined;
  cadastro:boolean = true;
  isLoading = false;

  constructor(private parceiroService: ParceiroService,
    public dialog: MatDialog) {
    // this.parceiro = {
    //   nomeEmpresa: "Teste empresa site parceiro",
    //   nomeContato: "Guilherme Alves Reis",
    //   foneContato: 61999997288,
    //   email: "guialves@gmail.com ",
    //   site: "teste.com.br",
    //   numCarteira: "asdf1a32sd165a4dsf31asf3d213asd"
    // }
    // this.uploadedFileUrl = 'asdfasdfasdf'
  }
  ngOnInit(): void {
    this.cadastro = true;
    this.isLoading = false;
  }

  salvar(form: FormGroup) {
    if (form.invalid) {
      return;
    }
    this.isLoading = true;
    this.parceiroService.salvar(this.arqLogo, this.parceiro).subscribe({
      next: (response) => {
        this.cadastro = false;
        this.isLoading = false;
      },
      error: (error) => {
        console.log('error: ', error);
        this.openDialog(error);
        this.isLoading = false;
      }
    });
  }

  openDialog(errorMessage: string) {
    this.dialog.open(ErrorDialogComponent, {
      data: { message: errorMessage }
    });
  }

  onComplete = (files: UploadWidgetResult[]) => {
    console.log('Arquivo: ', files[0]);
    this.uploadedFileUrl = files[0]?.fileUrl;
    this.arqLogo = files[0]?.originalFile?.file;
  };


  options: UploadWidgetConfig = {
    apiKey: "public_kW15cDx69q2QB5WcBYsCeqarYd4E", // Get API key: https://www.bytescale.com/get-started
    maxFileCount: 1,
    "maxFileSizeBytes": 10485760,
    "mimeTypes": [
      "image/png"
    ],
    "multi": false,
    "editor": {
      images: {
        allowResizeOnMove: false, // Desativa a redimensionamento
        crop: false, // Desativa o corte
        preview: true // Desativa a pré-visualização
      }
    },
    "locale": {
      "addAnotherFileBtn": "Adicionar outro arquivo...",
      "addAnotherImageBtn": "Adicionar outra imagem...",
      "cancelBtn": "Cancelar",
      "cancelBtnClicked": "Cancelado",
      "cancelPreviewBtn": "Cancelar",
      "continueBtn": "Continuar",
      "cropBtn": "Cortar",
      "customValidationFailed": "Falha ao validar o arquivo.",
      "doneBtn": "Concluído",
      "fileSizeLimitPrefix": "Limite de tamanho do arquivo:",
      "finishBtn": "Finalizado",
      "finishBtnIcon": true,
      "imageCropNumberPrefix": "Imagem",
      "maxFilesReachedPrefix": "Número máximo de arquivos:",
      "maxImagesReachedPrefix": "Número máximo de imagens:",
      "orDragDropFile": "...ou arraste e solte um arquivo.",
      "orDragDropFileMulti": "...ou arraste e solte arquivos.",
      "orDragDropImage": "Selecione um imagem com fundo tranparente no formato PNG",
      "orDragDropImageMulti": "...ou arraste e solte imagens.",
      "processingFile": "Processando arquivo...",
      "removeBtn": "Remover",
      "removeBtnClicked": "Removido",
      "submitBtnError": "Erro!",
      "submitBtnLoading": "Por favor, aguarde...",
      "unsupportedFileType": "Tipo de arquivo não suportado.",
      "uploadFileBtn": "Carregar um arquivo",
      "uploadFileMultiBtn": "Carregar arquivos",
      "uploadImageBtn": "Carregar uma imagem",
      "uploadImageMultiBtn": "Carregar imagens",
      "xOfY": "de"
    },
    "styles": {
      "breakpoints": {
        "fullScreenHeight": 420,
        "fullScreenWidth": 750
      },
      "colors": {
        "error": "#d23f4d",
        "primary": "#1f6306",
      },
      "fontFamilies": {
        "base": "-apple-system, blinkmacsystemfont, Segoe UI, helvetica, arial, sans-serif"
      },
      "fontSizes": {
        "base": 16
      }

    }

  };

}
